:root {
  --black: #181818;
  --light-black: #252526;
  --grey: #444444a1;
}
.dark {
  background-color: var(--black);
}

body {
  background-color: #eeeeee;
  height: 100vh;
  width: 100%;
}
* {
  box-sizing: border-box;
  margin: 0;
}
a {
  text-decoration: none;
  color: black;
}
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flexCenterCol {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.innerWidth {
  width: 100%;
}

.paddings {
  padding: 1.5rem;
}

@media (min-width: 1280px) {
  .innerWidth {
    width: 1280px;
  }
}

button {
  border: none;
  background-color: white;
  border-radius: 10px;
  padding: 0.5rem 0.8rem;
  text-transform: capitalize;
  border: 1px solid rgb(190, 190, 190);
}
button.dark:hover,
button:hover {
  background-color: rgba(0, 0, 0, 0.08);
  transition: 150ms;
}
button.dark {
  background: var(--grey);
  border: 1px solid rgb(94, 94, 94);
  color: white;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(54, 56, 58);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(122, 122, 122);
}
