.video-container {
    flex-wrap: wrap;
    gap: 1rem;
}
.video-card{
    align-items: flex-start;
    background-color: white;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;

    
}

.video-card.dark {
    background-color: var(--light-black);
    color: white;
}
.video-wrapper {
    background-color: black;
    max-width: 400px;
    border-radius: 5px;
}

.text-div {
    display: flex;
    flex-direction: column;
    width: 300px;
    justify-content: start;
}

.card-description {
    height: auto;
}

@media (max-width:780px) {
    .text-div{
        width: 100%;
        padding: 0 5% 1rem 5%;
        max-height: 230px;
        overflow: auto;
    }
    
}