* {
    box-sizing: border-box;
}
.card-container {
    flex-wrap: wrap;
    margin: auto;
    gap: 2rem;
    justify-content: space-between;

}
.card {
    height: 550px;
    width: 300px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
    border-radius:15px;
    padding: 1.4rem 1rem 2rem 1rem;
    justify-content: space-between;
    margin: auto;
    background-color: white;

}

.card.dark {
    background-color: var(--light-black);
    color: white;

}
.img-container > img {
    height: 230px;
    width: 230px;
    object-fit: cover;
    overflow: hidden;
}

.img-container >img:hover {
    scale: 1.1;
    transition: 100ms ;
}
.card-description{
    height: 200px;
    overflow: auto;
    padding: 1rem;

}

.card-title {
    margin: 0 auto 1.2rem auto;
    font-size: 1.5rem;
}

.myAudio {
    position: fixed;
    bottom: 5px;
    right: 10px;
}

/* black backdrop */
.popup-image {
    position: fixed;
    top:0; left:0;
    height: 100%;
    width: 100%;
    background: rgba(0 , 0  , 0, 0.9);
    z-index: 100;
    display: flex;
    padding: 1rem;
}

.popup-image > img {
    height: 90%;
    width: auto;
    margin: auto;
    border: white 2px solid;
}

@media (max-width:768px) {
    .popup-image img {
        width: 95%;
        height: auto;

    }
}