.login-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.login-form {
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 400px;
  background-color: white;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 20px;
  box-shadow: 0px 1px 5px 0px rgb(0, 0, 0,0.2);
  gap: 1rem;
}

label {
  margin-bottom: -1rem;
}